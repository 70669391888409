/**
 * @class PanelContainer
 * display search results
 */

import * as React from "react";
import styles from "../styles.css";

export default class ResultsPanel extends React.Component<
  { results: any; onSelectedResult: Function; resetPanelOptions: Function },
  {}
> {
  handleResultClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: object
  ) => {
    e.preventDefault();
    this.props.onSelectedResult(value);
  };
  
  render() {
    const { results, resetPanelOptions }: any = this.props;
    return (
      <div style={{textAlign: "left"}}>
          <span onClick={resetPanelOptions} style={{fontSize: "13.2px", color: "#4183c4", cursor: "pointer", textAlign: "left", display: "inline"}}>← volver </span>

          <div style={{overflowY: "auto", maxHeight: "118px", marginTop: "3px"}}>
            {Object.keys(results).map((key: any, index: number) => (
              <div
                key={index}
                className={styles.result__item}
                onClick={e => this.handleResultClick(e, results[key])}>
                <span>{results[key].label}</span>
              </div>
            ))}
          </div>

          {Object.keys(results).length === 0 && 
            <p style={{textAlign: "left", fontSize: "13.2px", marginTop: "10px"}}>No se han encontrado resultados.</p>
          }
      </div>
    );
  }
}
