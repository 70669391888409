/**
 * @class PanelContainer
 * display options to select
 */

import * as React from 'react';
import styles from '../styles.css';

export default class OptionsPanel extends React.Component<{ options: any; onSelectOption: Function }, {}> {
	handleSelectedOption = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, label: string, type: string, resultTemplate: string) => {
		e.preventDefault();
		this.props.onSelectOption({label: label, type: type, resultTemplate: resultTemplate});
	};
	
	render() {
		const options: any = this.props.options;
		return (
			<div>
				{options.map((item: any, index: number) => (
					<div
						key={index}
						className={styles.option__item}
						onClick={(e) => this.handleSelectedOption(e, item.label, item.type, item.resultTemplate)}
					>
						<img src={item.icon} alt={item.label} width="24" height="24" />
						<span>{item.label}</span>
					</div>
				))}
			</div>
		);
	}
}
